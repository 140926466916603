import axios from "axios";

export default abstract class SWRData {
    isLoading: boolean
    isError: boolean
}

export const fetcher = (url: RequestInfo | URL) => fetch(url)
    .then(res => {
        if (res.status === 401) {
            window.location.href = `/api/goodbye?autoLogout=true`;
            return;
        }
        if (res.status === 500) {
            console.log(`api error`, res.statusText, res.body);
            return null;
        }

        const contentType = res.headers.get('content-type');
        if (contentType?.startsWith('text/')) {
            return res.text();
        }

        return res.json();
    });
