import Link from "next/link";

import {Entity} from "../../lib/api/entity";
import {MenuList} from "../../lib/api/menu-list";

type MenuTileProps = {
    entity: Entity
    menu: MenuList
}

export default function MenuTile({ entity , menu }: MenuTileProps) {

    const isDraft = menu.publishedMenu == null || menu.hash !== menu.publishedMenu.hash;
    const showPublishedMenuDownloadLink = menu.publishedMenu && menu.hash !== menu.publishedMenu.hash;

    return (
        <>
            <Link href={`/menu/${menu.id}/edit`}
                  title={`${menu.title} - ${entity.name} - ${entity.formattedAddress} - ${entity.location}`}>
                <a className={"mb-2 me-3 menu-tile menu-tile-draft"}>
                    <h5>{menu.title} <span className={`badge rounded-pill ${isDraft ? 'bg-primary' : 'bg-success'}`}>{isDraft ? "draft" : "published"}</span></h5>
                    <div className="menu-thumbnail" style={{ backgroundImage: `url(${menu.thumbnail})`}}>
                    </div>
                </a>
            </Link>
            {showPublishedMenuDownloadLink && (
                <Link href={menu.publishedMenu.pdf}

                      title="{{name}} - {{../title}} - {{../entity.name}} - {{../entity.formattedAddress}} - {{../entity.location}}">
                    <a className="mb-2 me-3 menu-tile menu-tile-published">
                        <h5>{menu.title} <span className="badge rounded-pill bg-success">published</span></h5>
                        <div className="menu-thumbnail" style={{ backgroundImage: `url(${menu.thumbnail})`}}>
                        </div>
                    </a>
                </Link>
            )}
        </>
    );
}
