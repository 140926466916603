import { ReactNode } from "react";

import { Entity } from "../lib/api/entity";

import Header from './header'
import StandardHeaderContent from "./standard-header-content";

type LayoutProps = {
    children: ReactNode;
    entity?: Entity;
    isShowingMenu? : boolean;
}

const Layout = ({ children, entity, isShowingMenu }: LayoutProps) => {
    return (
        <>
            <div className="d-flex flex-column full-height">
                <Header isShowingMenu={isShowingMenu == null ? true : isShowingMenu}
                        entity={entity}/>

                {children}
            </div>

            <style jsx>{`
            `}</style>
            <style jsx global>{`
            `}</style>
        </>
    )
}

export default Layout
