import axios from 'axios';
import Image from 'next/future/image';
import Link from 'next/link'
import md5 from 'md5';

import Entity from '../lib/entity';
import { useFetchUser } from "../lib/user";
import { useFlapjackProfile } from "../lib/api/userrecord";

type HeaderProps = {
  isShowingMenu: boolean
  entity? : Entity
}

function sendToLogout(auth0domain: string) {
    const host = window.location.host;
    const url = `${auth0domain}/v2/logout?returnTo=${(host.startsWith('localhost') ? 'http' : 'https')}%3A%2F%2F${host}%2Fapi%2Flogin`;
    console.log("sending to ", url);
    window.location.replace(url);
}

const Header = ({ entity, isShowingMenu }: HeaderProps) => {
    const { user, isLoading, isError } = useFetchUser();
    const { profile }  = useFlapjackProfile();

    if (!isLoading && (isError || !user)) {
        axios.get<string>('/api/auth0endpoint').then(auth0 => {
            sendToLogout(auth0.data);
        });
    }

    return (
      <nav id="PageHeader" className="navbar">
        <div className="container-fluid">
          <div className="navbar-brand">
            {isShowingMenu &&
              <Link href="/home">
                <a className="me-2">
                  <svg width="20px" height="20px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m49.84 19.684-37.145 37.461c-6.9844 6.668-17.461-3.8086-10.477-10.477l42.539-42.539c2.8555-2.8555 7.6172-2.8555 10.477 0l42.539 42.539c6.668 6.668-3.8086 17.145-10.477 10.477l-37.461-37.461zm37.461 41.586v36.824h-26.031v-25.715c0-3.1758-2.5391-5.7148-5.7148-5.7148h-11.43c-2.8555 0-5.3984 2.5391-5.3984 5.7148v25.715h-26.348v-36.824c0.95312-0.63672 1.9062-1.2695 2.5391-1.9062l34.922-34.922 35.238 34.922c0.63672 0.63672 1.5859 1.2695 2.2227 1.9062z"
                        fill="#de6c01" fillRule="evenodd"/>
                  </svg>
                </a>
              </Link>}
            {entity && (<>{entity.name} <span className="address">{entity.location}</span></>)}
            {!isShowingMenu && (<Image src="/img/flapjack-full-logo.svg" height={34.64} width={126} alt={"Flapjack"} />)}
          </div>
          {user ? (
            <>
              <div className="d-flex">
                <a className="avatar" href="#">
                {profile && (<Image src={`https://robohash.org/${md5(profile.displayName)}?size=200x200`}
                                    height={30} width={32}
                                    title={user.name} alt={user.name} />)}
                </a>
                <Link href={`/api/goodbye`}>log out</Link>
              </div>
            </>) : (
            <>
              <li>
                <Link href="/api/login">
                  <a>Login</a>
                </Link>
              </li>
            </>)}
        </div>

      <style jsx>{`
       
      `}</style>
    </nav>)
}

export default Header
