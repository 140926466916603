import useSwr from 'swr';

import { PublishedMenu } from "./publishedmenu";
import { Entity } from "./entity";
import SWRData, { fetcher } from './SWRResponse';
import { MenuRecord } from "./menurecord";

export type MenuList = {
    'hash' : string;
    'id' : number;
    'publishedMenu' ? : PublishedMenu;
    'thumbnail' ? : string;
    'title' : string;
    'entity' : Entity;
};

type MenuListCache = {
    list: MenuList[],
}

export interface MenuListData extends SWRData {
    menuList: Array<MenuList>,
}

export function useMenuRecordForEntity(menu: MenuRecord): MenuListData {
    const { data, error } = useSwr<MenuList[]>(() => '/api/menus/' + menu.entity.id, fetcher);

    return {
        menuList: data,
        isLoading: !data && !error,
        isError: error != null,
    }
}

export default function useMenuList(): MenuListData {
    const { data, error, isValidating, mutate } = useSwr<MenuList[]>('/api/menus', fetcher);

    if (!data && window.__menuList) {
        console.log(`Using cached menu list`);
        return {
            menuList: window.__menuList,
            isError: false,
            isLoading: false,
        };
    } else if (data) {
        window.__menuList = {
             list: data
        };
    }

    if (error) {
        console.error(error);
    }

    return {
        menuList: data,
        isLoading: !data && !error && isValidating,
        isError: error != null,
    }
}
