import useSwr from "swr";
import SWRData, { fetcher } from "./SWRResponse";

export type FlapjackUserProfile = {
    email: string;
    displayName: string;
    phoneNumber?: string;
    userId: string;
    isAdmin: boolean;
    isSetup: boolean;
    roles: { [entityId: string]: string[] };
}

export type UpdateFlapjackUserProfile = {
    firstName: string;
    lastName: string;
    email: string;
}

export interface FlapjackProfileData extends SWRData {
    profile: FlapjackUserProfile,
}

export function useFlapjackProfile(): FlapjackProfileData {
    const { data, error, isValidating } = useSwr<FlapjackUserProfile>(`/api/profile`, fetcher);

    return {
        profile: data,
        isLoading: !data && !error && isValidating,
        isError: error != null,
    }
}