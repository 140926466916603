import _ from 'lodash';
import { withPageAuthRequired }  from "@auth0/nextjs-auth0";

import Layout from '../components/layout';
import LocationMenus from '../components/home/location-menus';
import useMenuList, { MenuList } from '../lib/api/menu-list';
import { Entity } from "../lib/api/entity";
import StandardHeaderContent from "../components/standard-header-content";

function Home() {
    const menuData = useMenuList();

    // if (menuData.isLoading || menuData.isError) {
    //     return (<>
    //         Loading ...
    //     </>);
    // }
    //
    // if (!menuData.menuList) {
    //     return (
    //         <Layout isShowingMenu={false}>
    //             <StandardHeaderContent />
    //             <div id="Locations" className="container-fluid">
    //                 <div className="row">
    //                     <div className="col px-5 py-5">
    //                         <p>
    //                             The server is current unavailable.<br />
    //                             This is a temporary error, please refresh the page in a few moments.
    //                         </p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </Layout>
    //     )
    // }

    const entities: Array<Entity> = (menuData?.menuList || []).map(menu => menu.entity).uniqBy(e => e.id);
    const byEntity: { [id: number]: Array<MenuList> } = {}
    entities.forEach(entity => byEntity[entity.id] = []);

    (menuData?.menuList || []).forEach(menu => byEntity[menu.entity.id].push(menu));

    return (
        <Layout isShowingMenu={false}>
            <StandardHeaderContent />
            <div id="Locations" className="container-fluid">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10 mt-5">
                    {entities.map(entity => (
                        <LocationMenus key={`entity${entity.id}`}
                                       menus={byEntity[entity.id]}
                                       entity={entity} />
                    ))}
                    </div>
                </div>

            </div>
        </Layout>
    );
}

export default withPageAuthRequired(Home);
