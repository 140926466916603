import { UserProfile } from '@auth0/nextjs-auth0';
import useSwr from 'swr';

const fetcher = (url: RequestInfo | URL) => fetch(url).then(res => res.json());

export function useFetchUser() {
  const { data, error } = useSwr('/api/me', fetcher);

  const json: UserProfile = data;

  if (json && json.error) {
    console.log(json.error);
    return {
      user: null,
      isLoading: false,
      isError: json.error === 'not_authenticated',
    }
  }

  return {
    user: json,
    isLoading: !data && !error,
    isError: error,
  };
}
