import {Entity} from "../../lib/api/entity";
import {MenuList} from "../../lib/api/menu-list";
import MenuTile from "./menu-tile";

type LocationMenusProps = {
    menus: Array<MenuList>
    entity: Entity
}

export default function LocationMenus({ menus, entity }: LocationMenusProps) {

    return (<>
        <div key={`location-title-${entity.id}`} className="row">
            <div className="col">
                <div className="entity-title">
                    <span className="entity-name">{entity.name}</span>{entity.location}
                </div>
            </div>
        </div>
        <div key={`location-row-${entity.id}`} className="row">
            <div className="col-12">
                <div className="d-inline-flex flex-row full-width menu-tile-container flex-wrap mb-4">
                    {menus.map((menu: MenuList) => (
                        <MenuTile key={`menu-${menu.id}`} entity={entity} menu={menu} />
                    ))}
                </div>
            </div>
        </div>
    </>)
}
